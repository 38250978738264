import React from "react";
import "./index.css";
import { useCallback } from "react";

const BookCard = (props) => {
  const handleClick = useCallback((param) => {
    window.open(param, "_blank");
  }, []);

  return (
    <div className="BookContainer">
      <div className="BookPhoto">
        <img
          src={props.PhotoURL}
          alt="PhotoURL"
          className="BookImage"
          style={{ width: "200px", height: "240px", cursor: "pointer" }}
          onClick={() => handleClick(props.File)}
        />
      </div>
      <div className="BookIntro">
        <div className="BookTitle" onClick={() => handleClick(props.File)}>
          {props.Title}
        </div>
        <div className="BookAuthor">{props.Author}</div>
        <div className="BookScore">
          <div>{props.Score}</div>
          <img
            src="books/bs2.png"
            alt="Best"
            className="BestImage"
            style={{ width: "60px", height: "30px" }}
          />
        </div>
        <div className="BookDesc">{props.Desc}</div>
      </div>
    </div>
  );
};
export default BookCard;
