import "./App.css";
//import MyBook from "./components/Test/MyBook";
//import MyFlex from "./components/Test/MyFlex";
import Gallery from "./components/Gallery";
import TabComponent from "./components/Tab";
import ReactGA from "react-ga";
import { useEffect } from "react";

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    console.log("App Mounted");
  }, []);

  // 这里目前是 3 个元素，分别是标题、内容、底部 Tab
  return (
    <div className="App">
      <div className="Title">Insight Reader</div>
      <div className="ContentPanel">
        <Gallery />
      </div>
      <div className="TabComponent">
        <TabComponent />
      </div>
    </div>
  );
}

export default App;
