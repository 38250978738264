const BooksArray = [
  {
    ID: "1",
    Photo: "books/1.jpg",
    Title: "Nexus",
    Author: "by Yuval Noah Harari",
    Score: "⭐⭐⭐",
    Desc: "Nexus delves into the role of human and AIdriven information networks in shaping society, power, and reality. It offers a critical exploration of how information influences  collective behavior, political systems, and humanity's future,  drawing connections between myth, technology, and the quest for truth in a complex world. ",
    File: "https://drive.google.com/file/d/1HoSlOuhAePgsT_pHLIow6-VVAwAUBRqX/view",
  },
  {
    ID: "2",
    Photo: "books/2.jpg",
    Title: "A Court of Frost and Starlight",
    Author: "by Sarah J. Maas",
    Score: "⭐⭐⭐⭐",
    Desc: "revisits the characters from her acclaimed A Court of Thorns and Roses series. This companion tale explores the aftermath of war and the process of healing, with the characters preparing for the Winter Solstice and grappling with their scars, love, and new beginnings.",
    File: "https://drive.google.com/file/d/1B483cjF8bLhOQfDn-RAY_7eLd3uKMvFB/view",
  },
  {
    ID: "3",
    Photo: "books/3.jpg",
    Title: "A Court of Mist and Fury",
    Author: "by Sarah J. Maas",
    Score: "⭐⭐⭐⭐",
    Desc: "The novel delves into themes of trauma, healing, and self-discovery as Feyre struggles with her new identity and role after surviving the horrors Under the Mountain. It masterfully blends fantasy and romance with rich character development.",
    File: "https://drive.google.com/file/d/1AOJyKa8PV0G-vwyqR1RHIdxeo1Cb8mwK/view?usp=sharing",
  },
  {
    ID: "4",
    Photo: "books/4.jpg",
    Title: "A Court of Thorns and Roses",
    Author: "by Sarah J. Maas",
    Score: "⭐⭐⭐⭐",
    Desc: "This captivating fantasy novel blends romance and adventure, exploring themes of survival, sacrifice, and loyalty. Set in a richly imagined world where humans and faeries coexist, the story follows Feyre, a young huntress, as she navigates a dangerous faerie realm after making a life-altering decision. Perfect for fans of strong heroines and sweeping, magical worlds. ",
    File: "https://drive.google.com/file/d/1vbkXd5x158uqrt72lQCasUOQTr-lc-XQ/view?usp=sharing",
  },
  {
    ID: "5",
    Photo: "books/5.jpg",
    Title: "A Court of Sliver Flames",
    Author: "by Sarah J. Ma",
    Score: "⭐⭐⭐⭐",
    Desc: "This enthralling fantasy novel weaves together romance, adventure, and survival in a world where humans and faeries are at odds. Feyre, a young huntress, must navigate the treacherous faerie realm after a life-changing decision, discovering danger, love, and power in unexpected places. Fans of strong heroines and complex magical worlds will find much to love in this gripping tale. ",
    File: "https://drive.google.com/file/d/15stACk-dDopuJkdOPQVz-l83RGVgNS-c/view?usp=sharing",
  },
  {
    ID: "6",
    Photo: "books/6.jpg",
    Title: "A Court of Wings and Ruin ",
    Author: "by Sarah J. Maas",
    Score: "⭐⭐⭐⭐",
    Desc: "For readers who are captivated by tales of resilience, power struggles, and love set against the backdrop of a richly developed fantasy world, A Court of Wings and Ruin continues to deliver a highstakes narrative. It offers a complex storyline full of unexpected twists and unforgettable characters, appealing to fans of epic fantasy.",
    File: "https://drive.google.com/file/d/1ctHyUz5oeMgGj06vn1x9DRRy12zHp8uL/view?usp=sharing",
  },
  {
    ID: "7",
    Photo: "books/7.jpg",
    Title: "Infectious Generosity",
    Author: "by Chris Anderson",
    Score: "⭐⭐⭐⭐⭐",
    Desc: "An inspiring exploration of how acts of kindness and generosity can create lasting social impact, fostering communities and transforming lives. A must-read for anyone interested in philanthropy and social change.",
    File: "https://drive.google.com/file/d/1iVgmkFGyWstRsYEw2ipmN5MdY4IekQsn/view",
  },
  {
    ID: "8",
    Photo: "books/8.jpg",
    Title: "An Elephant in the Garden ",
    Author: "by Michael Morpurgo",
    Score: "⭐⭐⭐⭐",
    Desc: "A captivating and heartwarming story by Michael Morpurgo that blends historical events with the moving bond between a girl and an elephant. Set against the backdrop of World War II, this novel explores themes of survival, compassion, and the enduring spirit of hope.",
    File: "https://drive.google.com/file/d/1LTZF1BfVI9R4LZO2Ul_-Qio_9a8uiF_7/view?usp=sharing",
  },
  {
    ID: "9",
    Photo: "books/9.jpg",
    Title: "Camino Ghosts",
    Author: "by John Grisham",
    Score: "⭐⭐⭐⭐",
    Desc: "Takes readers back to Camino Island, weaving a thrilling narrative filled with intrigue, mystery, and the unforgettable cast of characters that captivated readers in Camino Island. Perfect for fans of suspense and literary thrillers, this novel delves into the island’s secrets, where old and new mysteries collide. ",
    File: "https://drive.google.com/file/d/14K1WoamL97E7LsIYpx5JfqVLZy8Q7x4l/view?usp=sharing",
  },
  {
    ID: "10",
    Photo: "books/10.jpg",
    Title: "Every Summer After",
    Author: "by Carley Fortune",
    Score: "⭐⭐⭐⭐",
    Desc: "A heartfelt and nostalgic tale of love, loss, and the passage of time. The novel captures the essence of summers gone by and the life-changing relationships that shape us. With a focus on romance and personal growth, it is a touching story about forgiveness and second chances.",
    File: "https://drive.google.com/file/d/1BcQ-eDihz6e86Sll4K6ipLy9Ek5Hlujg/view?usp=sharing",
  },
  {
    ID: "11",
    Photo: "books/11.jpg",
    Title: "All the Colors of the Dark",
    Author: "by Chris Whitaker",
    Score: "⭐⭐⭐⭐",
    Desc: "A captivating thriller that delves into the depths of human emotion, darkness, and resilience. Set against the backdrop of a mysterious small town, the novel weaves complex characters and suspenseful events into a heart-wrenching narrative about survival, sacrifice, and the quest for truth.",
    File: "https://drive.google.com/file/d/188__7x0JL49J7OeQ-o-7_9iyoUlzUe4a/view?usp=sharing",
  },
  {
    ID: "12",
    Photo: "books/12.jpg",
    Title: "The Keeper of Happy Endings",
    Author: "by Barbara Davis",
    Score: "⭐⭐⭐⭐",
    Desc: "This beautiful novel blends historical fiction and emotional depth, as Barbara Davis takes readers on a journey of love, loss, and self-discovery. The Keeper of Happy Endings is a heartfelt and compelling story that will resonate deeply with readers.",
    File: "https://drive.google.com/file/d/1BRd80-cYe7vBscxytye1YGy4oai10ls1/view?usp=sharing",
  },
  {
    ID: "13",
    Photo: "books/13.jpg",
    Title: "The Housemaid",
    Author: "by Freida McFadden",
    Score: "⭐⭐⭐⭐",
    Desc: "Agripping psychological thriller that will leave you breathless. Full of unexpected twists and tension, it pulls you into the life of Millie, a woman with a troubled past who finds herself working in a seemingly perfect home with dangerous secrets. A must-read for fans of thrillers who enjoy unraveling dark, twisted mysteries.",
    File: "https://drive.google.com/file/d/1rxA4dipqQJEx0DNROlVKHOVfPAbAGJN4/view?usp=sharing",
  },
  {
    ID: "14",
    Photo: "books/14.jpg",
    Title: "The Pumpkin Spice Café",
    Author: "by Laurie Gilmore",
    Score: "⭐⭐⭐⭐",
    Desc: "A charming story set in the quaint town of Dream Harbor. This heartwarming novel follows Jeanie Ellis, a woman starting anew as the owner of a beloved local café. With delightful characters, lighthearted humor, and a dash of romance, it’s perfect for anyone seeking a cozy and uplifting read.",
    File: "https://drive.google.com/file/d/1xsnuVYLwtRDaB8Tw5R1RryLeDFjoGk-Y/view?usp=sharing",
  },
  {
    ID: "15",
    Photo: "books/15.jpg",
    Title: "Good Energy",
    Author: "by Casey Means, MD, with Calley Means",
    Score: "⭐⭐⭐⭐",
    Desc: "An insightful and transformative book that reveals the critical role metabolism plays in modern health. With scientific clarity, it explores how our energy production affects everything from mental clarity to disease prevention. Dr. Means offers practical, actionable steps to improve metabolic health, making this a must-read for anyone seeking to live a healthier, more vibrant life.",
    File: "https://drive.google.com/file/d/1-nU_EJlWHX3OHOUdXZr4ooUKD39iJxM2/view?usp=sharing",
  },
  {
    ID: "16",
    Photo: "books/16.jpg",
    Title: "Trust",
    Author: "by Hernan Diaz",
    Score: "⭐⭐⭐⭐",
    Desc: "A multi-layered exploration of wealth, power, and the nature of truth. With its intricate narrative structure and compelling characters, this novel invites readers into a world where personal histories and public myths intersect. Diaz's storytelling weaves together different perspectives, challenging our understanding of reality and fiction.",
    File: "https://drive.google.com/file/d/1WqYAEzFsBvO_gH-IkCw-afoUr7TlbyGk/view?usp=sharing",
  },
  {
    ID: "17",
    Photo: "books/17.jpg",
    Title: "Reminders of Him",
    Author: "by Colleen Hoover",
    Score: "⭐⭐⭐⭐",
    Desc: "An emotional and poignant novel about love, redemption, and forgiveness. Following the life of a mother trying to reconnect with her daughter after serving time in prison, this story offers deep insights into the complexity of relationships and healing from the past. A must-read for fans of emotional and heartfelt fiction.",
    File: "https://drive.google.com/file/d/1WFRm6gJFr7nG_hSdhD9kqVSGy1g5xq9h/view?usp=sharing",
  },
  {
    ID: "18",
    Photo: "books/18.jpg",
    Title: "Horse: A Novel",
    Author: "by Geraldine Brooks",
    Score: "⭐⭐⭐⭐",
    Desc: "A captivating and deeply moving historical fiction that spans multiple timelines and explores the intertwined lives of people and horses. The novel offers a rich narrative filled with history, emotion, and the powerful connection between human and animal life.",
    File: "https://drive.google.com/file/d/1WwEDMmI_J0IqTfINeJv59GvFf4yvrj9Q/view?usp=sharing",
  },
  {
    ID: "19",
    Photo: "books/19.jpg",
    Title: "Ikigai: The Japanese Secret to a Long and Happy Life",
    Author: "by Héctor García and Francesc Miralles",
    Score: "⭐⭐⭐⭐",
    Desc: "Offers readers timeless insights into living with purpose and finding joy in the everyday. Drawing on the wisdom of Okinawan centenarians and Japanese philosophy, the book explores the keys to a long and fulfilling life through diet, community, resilience, and mindful living.",
    File: "https://drive.google.com/file/d/1TfzVaXgBYyDIDVhkhBYaKuwgYnzH0bQN/view?usp=sharing",
  },
  {
    ID: "20",
    Photo: "books/20.jpg",
    Title: "Iron Flame",
    Author: "by Rebecca Yarros",
    Score: "⭐⭐⭐⭐",
    Desc: "A thrilling adventure set in a world of dragons, war, and rebellion. This military fantasy novel weaves intricate relationships, high-stakes challenges, and personal resilience to immerse readers in the competitive and dangerous world of Basgiath War College. Fans of adventure, fantasy, and dynamic character growth will find this novel a gripping read.",
    File: "https://drive.google.com/file/d/1f8-O4-glMk6fkcHjq32EHjUYdHMa9vlp/view?usp=sharing",
  },
  {
    ID: "21",
    Photo: "books/21.jpg",
    Title: "It Ends with Us",
    Author: "by Colleen Hoover",
    Score: "⭐⭐⭐⭐",
    Desc: "A deeply emotional story that explores the complexities of love, abuse, and self-discovery. Through the journey of Lily Bloom, readers are taken on a heart-wrenching yet hopeful path where difficult choices and life-changing moments converge. This is a gripping tale that sheds light on the painful reality of toxic relationships while advocating for self-worth and healing.",
    File: "https://drive.google.com/file/d/1KbD-xEhE8V5kChuO2btTki9v0-TT3LGs/view?usp=sharing",
  },
  {
    ID: "22",
    Photo: "books/22.jpg",
    Title: "It Starts with Us",
    Author: "by Colleen Hoover",
    Score: "⭐⭐⭐⭐",
    Desc: "Follows the characters Lily and Atlas, as they explore the next chapter of their lives after the events of It Ends with Us. With emotional depth and thought-provoking themes, this sequel provides a heartfelt continuation of their journey of love, healing, and difficult decisions. A must-read for those captivated by the first book.",
    File: "https://drive.google.com/file/d/1fGHNFr-TGL9yIG21Gl1zW29ZSPx642tf/view?usp=sharing",
  },
  {
    ID: "23",
    Photo: "books/23.jpg",
    Title: "Lessons in Chemistry: A Novel",
    Author: "by Bonnie Garmus",
    Score: "⭐⭐⭐⭐",
    Desc: "A compelling tale set in the 1960s, centered around Elizabeth Zott, a brilliant chemist who unexpectedly becomes the star of a cooking show. Her journey explores themes of gender roles, societal expectations, and resilience, all with sharp wit and unforgettable characters. A must-read for those who enjoy stories of perseverance and breaking boundaries.",
    File: "https://drive.google.com/file/d/1dXFb_eSudWATo9TIVgcF1YNRbxK1MKsv/view?usp=sharing",
  },
  {
    ID: "24",
    Photo: "books/24.jpg",
    Title: "Here One Moment",
    Author: "by Liane Moriarty",
    Score: "⭐⭐⭐⭐",
    Desc: "A gripping novel that weaves mystery, human emotions, and relationships into a compelling narrative. Fans of Moriarty will appreciate her ability to delve into the intricacies of the human psyche, bringing her characters to life with their flaws, secrets, and strengths. A thrilling read for lovers of psychological fiction.",
    File: "https://drive.google.com/file/d/1De48L-d9rK2kXuH6ZWLOVibkm9MkqLZR/view?usp=sharing",
  },
  {
    ID: "25",
    Photo: "books/25.jpg",
    Title: "The Boy from Block 66",
    Author: "by Limor Regev",
    Score: "⭐⭐⭐⭐",
    Desc: "This novel recounts the harrowing true story of survival during World War II, focusing on the life of Moshe, a young Jewish boy sent to Auschwitz and other concentration camps. It is an emotional journey that emphasizes resilience, hope, and the human spirit's ability to overcome the darkest moments in history.",
    File: "https://drive.google.com/file/d/1j0qETOER7WbMyx7UFzIGj4nMZi2M3H1E/view?usp=sharing",
  },
  {
    ID: "26",
    Photo: "books/26.jpg",
    Title: "The Secret Life of Sunflowers",
    Author: "by Marta Molnar",
    Score: "⭐⭐⭐⭐",
    Desc: "A beautifully written novel about family, art, and mystery, The Secret Life of Sunflowers follows Emsley as she uncovers her grandmother’s past through a hidden diary, unveiling connections to Vincent van Gogh. This emotional journey weaves together historical intrigue and personal discovery, perfect for lovers of art and history.",
    File: "https://drive.google.com/file/d/19rebMWBrf1S_PCT9cYTwRmOI9UmZtDag/view?usp=sharing",
  },
  {
    ID: "27",
    Photo: "books/27.jpg",
    Title: "Counting Miracles",
    Author: "by Nicholas Sparks",
    Score: "⭐⭐⭐⭐",
    Desc: "A heartwarming novel that explores love, loss, and the search for belonging. Through the journey of Tanner Hughes, a man at a crossroads in life, the book beautifully illustrates the enduring power of family and the miracles that shape our lives.",
    File: "https://drive.google.com/file/d/1TkDEI8kyKzBudjgJbN0saL9HuMpXOVyi/view?usp=sharing",
  },
  {
    ID: "28",
    Photo: "books/28.jpg",
    Title: "Remarkably Bright Creatures",
    Author: "by Shelby Van Pelt",
    Score: "⭐⭐⭐⭐",
    Desc: "A poignant, heartwarming novel that intertwines the lives of a grieving widow and a clever octopus. It explores the themes of love, loss, and unlikely friendships, while weaving a beautiful story of healing and redemption through unexpected connections.",
    File: "https://drive.google.com/file/d/1r0P20kAlntKhpD5DdnreQrcVfe2quJ9j/view?usp=sharing",
  },
  {
    ID: "29",
    Photo: "books/29.jpg",
    Title: "The Women",
    Author: "by Kristin Hannah",
    Score: "⭐⭐⭐⭐",
    Desc: "This powerful novel explores the emotional and physical battles faced by women during the Vietnam War, highlighting resilience, sacrifice, and the strength of sisterhood. A compelling and heart-wrenching read.",
    File: "https://drive.google.com/file/d/1ZSIMUio_jqnQog1vqIB4su6moVyB7FRm/view?usp=sharing",
  },
];

export default BooksArray;
