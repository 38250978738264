import React from "react";
import ReactGA from "react-ga";
//import PDFViewer from "../components/PDFViewer";
import { useNavigate } from "react-router-dom";
import "./index.css";

const PageList = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const navigate = useNavigate();

  return (
    <div>
      <div className="CenterList">
        <button onClick={() => navigate(-1)}> Back to Home </button>
        <div>Page for book list</div>
      </div>
    </div>
  );
};

export default PageList;
