// src/component/Gallery/index.js
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Gallery.css";
import { yellow } from "@mui/material/colors";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };
    console.log("Gallery Constructor");
    //this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // 在组件挂载后（首次渲染完成后）被调用。
    console.log("Gallery Did Mounted");
  }

  componentDidUpdate() {
    // 在组件更新后被立即调用。首次渲染不会执行此方法。
    console.log("Gallery Did Update");
  }

  shouldComponentUpdate(nextProps, nextState) {
    // 在组件接收到新的 props 或 state 时被调用。在初始化时或者使用 forceUpdate 时不被调用。
    console.log("Gallery should Update");
    return true;
  }

  componentWillUnmount() {
    // 在组件卸载及销毁之前直接调用。
    console.log("Gallery Will Unmounted");
  }

  render() {
    return (
      <div>
        <Carousel
          autoPlay
          interval="6000"
          transitionTime="500"
          infiniteLoop
          showStatus={false}
          showThumbs={false}
        >
          <div>
            <img src="covers/p11.jpg" alt="" />
            <p
              className="legend"
              style={{
                opacity: 0.9,
                color: "while",
                fontWeight: "bold",
                fontSize: "30px",
                backgroundColor: "#229933",
              }}
            >
              Read a Book in Just 15 Minutes
            </p>
          </div>
          <div>
            <img src="covers/p22.jpg" alt="" />
            <p
              className="legend"
              style={{
                opacity: 0.9,
                color: "white",
                fontWeight: "bold",
                fontSize: "30px",
                backgroundColor: "#114477",
              }}
            >
              Condense a Full-Length Book to 10% Without Losing Key Insights
            </p>
          </div>
          <div>
            <img src="covers/p33.jpg" alt="" />
            <p
              className="legend"
              style={{
                opacity: 0.9,
                color: "brown",
                fontWeight: "bold",
                fontSize: "30px",
                backgroundColor: "white",
              }}
            >
              Read Like a Pro—Fast and Focused
            </p>
          </div>
          <div>
            <img src="covers/p44.jpg" alt="" />
            <p
              className="legend"
              style={{
                opacity: 0.9,
                color: "red",
                fontWeight: "bold",
                fontSize: "28px",
                backgroundColor: yellow[500],
              }}
            >
              Speak Confidently After a Quick Read
            </p>
          </div>
        </Carousel>
      </div>
    );
  }
}
export default Gallery;
