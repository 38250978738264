import React from "react";
import { Tabs, Tab } from "@mui/material";
import RecCard from "../RecCard";
import BookCard from "../BookCard/index.js";
import "./index.css";
import BooksArray from "./data.js";
import { useState, useEffect } from "react";

function TabPanel(props) {
  // 自己实现一个 TabPanel 组件，传入属性，children，value，index
  // children 显示在 Box 里面
  // value 是当前选中的 tab 的 index
  // index 是当前 tab 的 index
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function CollectionGrid() {
  // 数据
  const collectionsArray = [
    {
      ID: "0001",
      Photo: "collections/01.webp",
      Title: "Elon Musk's Book Recommendations",
    },
    {
      ID: "0002",
      Photo: "collections/02.jpg",
      Title: "Bill Gates's Book Recommendations",
    },
    {
      ID: "0003",
      Photo: "collections/03.jpg",
      Title: "Hillary Clintion's Book Recommendations",
    },
    {
      ID: "0004",
      Photo: "collections/04.jpg",
      Title: "Warren Buffett's Book Recommendations",
    },
    {
      ID: "0005",
      Photo: "collections/05.jpg",
      Title: "Career & Success",
    },
    {
      ID: "0006",
      Photo: "collections/06.webp",
      Title: "Technology & the Future",
    },
  ];

  return (
    <div>
      <div className="RecGridContainer">
        {collectionsArray.map((item) => (
          <div key={item.ID} className="RecCardContainer">
            <RecCard ID={item.ID} PhotoURL={item.Photo} Title={item.Title} />
          </div>
        ))}
      </div>
    </div>
  );
}

// 书籍列表
function BookList() {
  var cachedData = localStorage.getItem("myBooklist23");
  if (!cachedData) {
    cachedData = BooksArray;
  } else {
    cachedData = JSON.parse(cachedData);
  }
  const [data, setData] = useState(cachedData);

  useEffect(() => {
    // 请求网络数据
    fetch("/api/docs/booklist")
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData.data);
        localStorage.setItem("myBooklist23", JSON.stringify(jsonData.data));
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  }, []);

  return (
    <div>
      <div className="BookContainer">
        {data ? (
          data.map((item) => (
            <div key={item.ID} className="BookItem">
              <BookCard
                ID={item.ID}
                PhotoURL={item.Photo}
                Title={item.Title}
                Author={item.Author}
                Score={item.Score}
                Desc={item.Desc}
                File={item.File}
              />
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

const TabComponent = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Collections" />
        <Tab label="Latest" />
      </Tabs>
      <div className="TabPanel">
        <TabPanel key={0} value={value} index={0}>
          <CollectionGrid />
        </TabPanel>
        <TabPanel key={1} value={value} index={1}>
          <BookList />
        </TabPanel>
      </div>
    </div>
  );
};

export default TabComponent;
