import React from "react";
import ReactGA from "react-ga";
//import PDFViewer from "../components/PDFViewer";
import { useNavigate } from "react-router-dom";
const PageFile = () => {
  const navigate = useNavigate();
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <div className="CenterList">
        <button onClick={() => navigate(-1)}> Back to Home </button>
        <div>Page for PDF Book</div>
      </div>
    </div>
  );
};

export default PageFile;
